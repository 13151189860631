import React from 'react';
import PropTypes from 'prop-types';
import { InfoCurso, Duration, Views, Stars, Icon } from '../../Home/Cursos/Distancia/style';
import { CourseItem } from './styles';

const CourseItemSearch = (course) => {
    let rating = 5;
    const stars = [];
    if (typeof course.course.evaluations !== undefined && course.course.hasOwnProperty('evaluations') && course.course.evaluations.length > 0) {

        course.course.evaluations.forEach((item) => {
            if (item.type === 'RATING') {
                rating = item.average;
            }
        })

    }
    for (var i = 0; i < 5; i++) {
        rating > i ? stars.push("fas") : stars.push('far');
    }


    return (
        <>
            <CourseItem to={`${course.prefix}/${course.course.id}`}>
                <InfoCurso >
                    <h4 className="title m-0 col-6 pl-0">{course.course.title}</h4>
                    <Stars className="d-none d-md-flex">
                        {stars.map((star, i) => <i key={`star-${i}`} className={`${star} fa-star`} />)}
                        {rating &&
                            <span className="font-italic"> {rating}/5</span>}
                    </Stars>

                    {course.course.duration !== '' &&
                        <Duration className="d-none d-md-flex"><Icon className=" far fa-clock" />{course.course.duration}</Duration>}
                    <Views><Icon className="fas fa-eye" />{course.course.views}</Views>
                </InfoCurso>
            </CourseItem>
        </>
    );
};
CourseItemSearch.propTypes = {
    course: PropTypes.object.isRequired,
}
export default CourseItemSearch;