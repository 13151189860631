import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Navbar = styled.nav`
  @media (max-width: 991px) {
    .menu-bg {
      height: 100vh !important;
    }
  }
`;
export const Menu = styled.ul`
  list-style: none;
  display: flex;
  padding-inline-start: 0;
  background-color: #eeeeee;
  /* max-width: 85%; */
  padding: 0 !important;
  @media (max-width: 991px) {
    max-width: 350px;
    overflow: auto;
    padding: 0 0 70px;
  }
  z-index: 99;
`;
export const MenuItemLogin = styled(Link)`
  color: #fff;
  border-bottom: 1px solid #eeeeee4a;
  margin: 0 10px;
  padding: 8px 0;
  font-size: 0.9em;
  cursor: pointer;
  min-width: 110px;
`;
export const MenuItem = styled.li`
  display: flex;
  &.active:before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    background-color: #e5231a;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 991px) {
      bottom: 7px;
      left: 100%;
    }
  }
  &.active {
    position: relative;
  }
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
    padding: 5px 0px;
  }
`;
export const MenuLink = styled(Link)`
  color: #000;
  font-weight: 300;
  padding: 20px 15px;
  font-size: 18px;

  &:hover {
    text-decoration: none;
    color: #e5231a;
    opacity: 0.85;
  }
`;
export const LoginButtom = styled.div`
  &.logado {
    cursor: pointer;
    &:hover {
      background-color: #575756;
      @media (max-width: 992px) {
        background-color: transparent;
      }
      & span,
      i {
        color: #fff;
        line-height: 1;
        @media (max-width: 992px) {
          color: #000;
        }
      }
    }
    &:hover .dropdownLogin {
      display: flex;
    }
  }
`;
export const MenuLinkLogin = styled(Link)`
  color: #000;
  font-weight: 300;
  &:hover {
    text-decoration: none;
  }
`;
export const PerfilPhoto = styled.div`
  .boxPhoto {
    position: relative;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
    border-radius: 50%;
    & img {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      left: 0;
      top: 0;
    }
  }
`;
export const Dropdown = styled.div`
  color:#fff;
  background-color: #575756;
  display:none;
  position: absolute;
  top: 100%;
  flex-direction: column;
  width: 100%;
  left:0;
  justify-content: center;
  z-index:99;
  &:hover {
    display:flex;
    & a:hover {
      background-color: transparent;
      color: #fff;
      opacity: 0.65
      text-decoration: none;
    }
  
    & a:hover i {
        opacity: 0.65 !important;
    }

  }
  @media(max-width:991px){
    color: #252527;
    background-color: transparent;
    display: flex;
    position:relative;
    align-items: center;
    a{
      color: #252527;
      & i{
        margin-left:0!important;
      }
    }
    &:hover {
      & a:hover {
        color: #E5231A;
        opacity: 0.85;
        text-decoration: none;
      }
      & a:hover i {
          color: #E5231A !important;
          opacity: 0.85;
      }
  
    }
  }
`;
export const LoginIcon = styled.i`
  color: #000;
`;
export const FormStyle = styled.div`
  width: 100%;
  @media (max-width: 992px) {
    position: absolute;
    top: 0;
  }
  & input {
    border: none;
    background-color: #575756;
    color: #fff;
    padding: 0 15px;
    font-size: 12px;
    width: 100%;
    outline: none;
    height: 40px;
    padding-bottom: 20px;
    @media (max-width: 991px) {
      color: #252527;
      padding-bottom: 0px;
      border: none !important;
    }
    &::-webkit-input-placeholder {
      /* Edge */
      color: #fff;
      font-weight: bold;
      @media (max-width: 991px) {
        color: #252527;
      }
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
      font-weight: bold;
      @media (max-width: 991px) {
        color: #252527;
      }
    }

    &::placeholder {
      color: #fff;
      opacity: 0.65;
      font-weight: bold;
      @media (max-width: 991px) {
        color: #252527;
      }
    }
  }
  & .searchResponse {
    background-color: #eee;
    color: #252527;
    padding: 0 15px;
    transition: ease all 200ms;
  }
`;
export const ContainerSearchStyle = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 65px;
  z-index: 9999999;
  background-color: #575756;
  padding: 20px 0px 0;
  font-family: 'HelveticaLTStd', sans-serif;
  font-weight: 100;
  width: 500px;
  right: 0;
  &:hover {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: 991px) {
    position: relative;
    top: 0;
    padding: 0;
    opacity: 1;
    visibility: visible;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0 15px;
    background-color: transparent;
    width: 85%;
    & form {
      width: 100%;
    }
    & input {
      background-color: transparent;
      width: 100%;
    }
  }
`;
export const UserButtons = styled.ul`
  display: flex;
  flex-direction: column;
  position: inherit !important;
  span {
    color: #252527;
    line-height: 1;
    width: 100%;
    display: block;
    word-break: break-all;
  }

  .abreviateName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 2px;
    text-transform: capitalize;
  }
  @media (max-width: 991px) {
    span {
      text-align: center;
    }
  }
`;
export const SearchIcon = styled.i`
  color: #000;
  width: 100%;
  height: 100%;
  cursor: pointer;

  & .search-box.open {
    opacity: 1;
    visibility: visible;
  }
  &:hover,
  &.open {
    background-color: #575756;
    color: #fff;
  }
  @media (max-width: 991px) {
    &:hover,
    &.open {
      background-color: #eeeeee;
      color: #252527;
    }
  }
`;

export const CourseItem = styled(Link)`
  color: #000;
  text-decoration: none;
  cursor: pointer;
  transition: ease all 200ms;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #e5231a;
    display: block;
  }
`;
