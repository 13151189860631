import React from 'react';
import PropTypes from 'prop-types';
import {VideoStyle} from '../Cursos/Online/styles';

const VideoFile = ({url}) => {
  return(
    <VideoStyle controls>
        <source id="featuredVideo" src={url} type="video/mp4"/>
    </VideoStyle>
  )
};

VideoFile.propTypes ={
  url: PropTypes.string.isRequired
}
export default VideoFile;