import React, {useState, useEffect} from 'react';
import api from '../../services/api';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import {Spinner} from 'reactstrap';
import {Container} from './styles';

const Forum = () => {
  const [forum, setForum] = useState();
  const [loading, setLoading] = useState(false);

  const setForumToken = async () => {
    try {
      const response = await api.get('/flarum_sso/flarum/login');
      document.cookie = `${response.data.userCookie.split(';')[0]}; Domain=${
        process.env.REACT_APP_DOMAIN
      };`;
      document.cookie = `locale=pt-BR; Domain=${process.env.REACT_APP_DOMAIN};`;
    } catch (error) {
      console.error('Error ao salvar Token do Forum:', error);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await setForumToken();
      api
        .get('/settings/flarum_url')
        .then((response) => {
          setForum(response.data.value);
          setLoading(true);
        })
        .catch((error) => {
          console.error('Error ao exibir Forum:', error);
        });
    };

    fetch();
  }, []);

  return (
    <Base>
      <Title value="Fórum" />
      {!loading ? (
        <Spinner />
      ) : (
        forum && (
          <Container className="container">
            <iframe
              className="forum-iframe"
              src={forum}
              title="Fórum"
              width="100%"
              height="100%"
            />
          </Container>
        )
      )}
    </Base>
  );
};

export default Forum;
