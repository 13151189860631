import React, {useState, useEffect} from 'react';
import SubTitle from '../../components/Theme/Title/SubTitle';
import QuestionOption from './question';
import {Container, Col, Row} from 'reactstrap';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import api from '../../services/api';
import {NextArrow, PrevArrow} from './styles';
import HandleRefreshQuestions from './handleRefreshQuestion';

const Exame = ({exams, typeExame, ...props}) => {
  const [examsList, setExamsList] = useState([]);
  const [answersList, setAnswersList] = useState([]);
  const [responseExam, setResponseExam] = useState(null);

  function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
      <NextArrow
        id="next-question"
        onClick={onClick}
        className={`col-12 col-lg-3 LoadMoreButtom m-0 ${className}`}>
        Proxima Questão
      </NextArrow>
    );
  }

  function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
      <PrevArrow
        onClick={onClick}
        id="prev-question"
        className={`col-12 col-lg-3 LoadMoreButtom m-0 ${className}`}>
        Questão Anterior
      </PrevArrow>
    );
  }
  async function getExam(id_exam) {
    return new Promise((resolve) => {
      api
        .get(`/lms/exams/${id_exam}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error('Erro ao buscar Questions:', err);
        });
    });
  }
  useEffect(() => {
    let examTemp = [];
    exams.forEach(async (element) => {
      examTemp.push(await getExam(element.exam.id));
      setExamsList(examTemp);
    });
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const setAnswers = (exam_questions_id, exam_questions_alternative) => {
    let answersTemp = [];
    let exists = false;

    answersList.map((item) => {
      if (exists === false && item.exam_questions_id === exam_questions_id) {
        exists = true;
      } else {
        answersTemp.push(item);
      }
    });

    answersTemp.push({exam_questions_id, exam_questions_alternative});
    setAnswersList(answersTemp);
  };
  function submitExam(id_exam) {
    api
      .post(`/lms/exams/${id_exam}/submission?${typeExame}`, answersList)
      .then((res) => {
        setResponseExam(res.data);
      })
      .catch((err) => {
        console.error('Erro ao enviar questões: ', err);
      });
  }
  return (
    <div>
      {responseExam !== null ? (
        <Container className="text-center">
          <h3>
            O seu percentual de Acerto foi:{' '}
            {responseExam.percentage_correct_answers}%
          </h3>
          {responseExam.approved == true ? (
            <h1>
              Você foi <b>APROVADO.</b> Por favor, obtenha seu certificado!
              <i className="fas fa-check-double"></i>
            </h1>
          ) : (
            'Você foi REPROVADO. Por favor, refaça a prova.'
          )}
        </Container>
      ) : (
        <Container className="p-0 pb-5">
          {examsList.length > 0 && (
            <>
              <SubTitle value={examsList[0].title} />
              <HandleRefreshQuestions.Provider value={{setAnswers}}>
                <Slider {...settings}>
                  {examsList[0].exam_questions.map((question, i) => (
                    <QuestionOption key={`question-${i}`} opt={question} />
                  ))}
                </Slider>
                {examsList[0].exam_questions.length === answersList.length && (
                  <>
                    <NextArrow
                      id="submitAnswers"
                      onClick={(e) => submitExam(examsList[0].id)}
                      className="col-12 col-lg-3 LoadMoreButtom m-0">
                      Enviar Respostas
                    </NextArrow>
                  </>
                )}
              </HandleRefreshQuestions.Provider>
            </>
          )}
        </Container>
      )}
    </div>
  );
};
Exame.propTypes = {
  exams: PropTypes.array.isRequired,
  typeExame: PropTypes.string.isRequired,
};

export default Exame;
