import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const Header = styled.header`
  background: transparent;
  color: #ffffff;
  padding-top: 33px;
`;

export const Image = styled.img`
  width: 100%;
  min-width: 100%;
  margin-top: 5px;
  border-radius: 50%;
  border: 3px solid #fff;
  margin-bottom: -15px;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
`;
export const LinkLogo = styled(Link)``;
export const Logo = styled.img`
  background-color: #fff;
  width: auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export const MenuMobileStyle = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 1.6em;
  cursor: pointer;
`;
export const OverleyCompoment = styled.div`
  position: absolute;
  top: 0;
  background-color: #000000b0;
  z-index: 4;
  height: 100vh;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: ease all 300ms;
  &.active {
    opacity: 1;
    visibility: visible;
  }
`;
export const MenuStyle = styled.div`
  @media (max-width: 991px) {
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #eee;
    bottom: 0;
    z-index: 999;
    max-width: 350px !important;
    left: -100%;
    transition: ease all 300ms;
  }
`;
