import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Spinner } from 'reactstrap';
import App from './App';
import './assets/scss/base.scss';

import './config/i18n';
import './config/dateLocalization';
import { ModalBoxProvider } from './components/modal/context'

ReactDOM.render(
  <Suspense fallback={(
    <div className="init-loading text-center">
      <Spinner />
    </div>
  )}
  >
    <ModalBoxProvider>
      <App />
    </ModalBoxProvider>
  </Suspense>,
  document.getElementById('root'),
);
