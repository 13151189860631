import React, {useState} from 'react';
import {Row} from 'reactstrap';
import {Link} from 'react-router-dom';
import {
  BlockSection,
  BoxSobre,
  TitleSobre,
  TextContent,
  BannerImage,
} from './style';
import {Carousel} from 'react-responsive-carousel';

const BlockSobre = (props) => {
  const banners = props.content.banners;
  const [bannerList] = useState(banners.length > 0 ? banners : []);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showNav, setShowNav] = useState(banners.length > 1 ? true : false);

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev ? prev - 1 : 0));
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev < banners.length - 1 ? prev + 1 : 0));
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <BlockSection>
      <Carousel
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}>
        {bannerList.length > 0 &&
          bannerList.map((banner, i) => (
            <Row key={`banner-${i}`}>
              <BannerImage>
                <img src={banner.file.urlPublica} alt="banner" />
              </BannerImage>
              <TextContent className="col-3 d-flex align-items-start flex-column">
                {banner.title && <TitleSobre>{banner.title}</TitleSobre>}
                <BoxSobre>
                  {banner.description && (
                    <h3
                      dangerouslySetInnerHTML={{__html: banner.description}}
                    />
                  )}
                  <p>Saiba mais</p>
                  <Link to={banner.url}>
                    <i className="far fa-arrow-alt-circle-right" />
                  </Link>
                </BoxSobre>
              </TextContent>
            </Row>
          ))}
      </Carousel>
      <div className={`container slider-controls ${!showNav ? 'd-none' : ''}`}>
        <button onClick={(e) => handlePrev()}>
          <i className="far fa-arrow-alt-circle-left" />
        </button>
        <button onClick={(e) => handleNext()}>
          <i className="far fa-arrow-alt-circle-right" />
        </button>
      </div>
    </BlockSection>
  );
};

export default BlockSobre;
