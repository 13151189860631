import React, { useState } from 'react';
import { SearchIcon, FormStyle, ContainerSearchStyle } from './styles';
import api from '../../../services/api'
import CourseItemSearch from '../Navbar/courseItemSearch';

const SearchButton = () => {

  const [responseCourses, setResponseCourses] = useState([]);
  //const [responseClassRoom, setResponseClassRoom] = useState([]); // para cursos presenciais
  const [active, setActive] = useState(false);

  function typeSearch(inputTyped) {

    api.get(`/lms/courses?course_search=${inputTyped}`)
      .then((response) => {
        if (inputTyped !== '') {
          setResponseCourses(response.data);
        } else {
          setResponseCourses([]);
        }
      })

      //chamada a api para cursos presenciais
    /*api.get(`/lms/classroom_courses?course_search=${inputTyped}`)
      .then((response) => {
        if (inputTyped !== '') {
          setResponseClassRoom(response.data);
        } else {
          setResponseCourses([]);
        }
      })*/
  }

  return (
    <>
      <SearchIcon onClick={e => { setActive(prev => !prev) }} className={`fal fa-search d-flex align-items-center justify-content-center ${active ? 'open' : ''}`}>

        <ContainerSearchStyle className={`search-box ${active ? 'open' : ''}`}>
          <FormStyle onChange={(e) => typeSearch(e.target.value)} >
            <input type="text" placeholder="Pesquisar em todo Site" />
            <div className="searchResponse p-0">
              {responseCourses.length > 0 &&
                <>
                  <small className="px-2">CURSOS</small>
                  {responseCourses.map((course) => 
										<CourseItemSearch
											key={course.id}
											prefix="/online/lms/courses"
											course={course}
										/>
									)}
                </>
              }
              {
                // Exibicao dos cursos presenciais
              /*responseClassRoom.length > 0 &&
                <>
                  <small className="px-2">CURSOS PRESENCIAIS</small>
									{responseClassRoom.map((classroom, i) => 
										<CourseItemSearch
											prefix="/presencial/lms/classroom_courses"
											course={classroom} 
										/>
									)}
                </>
              */}
            </div>
          </FormStyle>
        </ContainerSearchStyle>
      </SearchIcon>
    </>
  );
};


export default SearchButton;
