import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const TitleBanner = styled.h4`
  padding: 0px;
  font-size: 30px;
  line-height: 33px;
  text-align: left;
  color: #fff;
  font-weight: 100;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  /* height: 132px; */
  overflow: hidden;
  @media (max-width: 991px) {
    font-size: 1em;
  }
`;

export const BannerItem = styled.div`
  margin: 0;
  padding: 30px;
  background-color: rgba(229, 35, 26, 0.9);
  border-radius: 0;
  width: 360px;
  top: 47%;
  transform: translateY(-50%);
  bottom: unset;
  position: absolute;
  text-align: left;
  font-weight: 100;
  max-height: 315px;
  height: 100%;
  max-height: 315px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    margin: 0px 0;
    width: 92%;
    height: 100%;
    opacity: 0.9;
    top: 50%;
  }
`;

export const DescriptionBanner = styled.p`
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 60px;
  overflow: hidden;
  @media (max-width: 991px) {
    font-size: 0.6em;
  }
`;
export const Banner = styled.div`
  &.Banner-item {
    height: 0;
    position: relative;
    padding-bottom: 37.2%;

    @media (max-width: 991px) {
      padding-bottom: 80%;
    }

    @media (max-width: 991px) {
      padding-bottom: 80%;
    }

    & img {
      position: absolute;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
    }
  }
`;
export const BannerLink = styled(Link)`
  color: #fff;
  font-size: 1.6em;
  font-weight: 500;
  text-align: left;
  &:hover {
    color: #fff;
    opacity: 0.65;
    text-decoration: none;
  }
`;
