import React, { useEffect, useState } from 'react';
import Base from '../../components/Theme/base'
import Title from '../../components/Theme/Title'
import { Col, Container, Row, Spinner } from 'reactstrap'
import api from '../../services/api'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LibraryList = () => {
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState([])

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/lms/courses?order[title]=asc&hasMaterials=true')
        const data = response.data || []

        setContent(data.filter(i => i.lessons.length && i.lessons.filter(m => m.lesson_support_materials.length).length))
        setLoading(false)
      } catch (_e) {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <Base>
      <Title value="Biblioteca" />

      <Container>
        {!loading ? (
          <Row>
            {content.map((item) => (
              <Col key={item.id} xs="12" md="6" lg="3" className="mb-4">
                <BoxItem>
                  <Link to={`/biblioteca/${item.id}`}>
                    <AspectRatio>
                      {item.image && (
                        <img src={item.image.urlPublica} alt={item.title} />
                      )}
                      <div className="meta-info">
                        <h3>{item.title}</h3>
                      </div>
                    </AspectRatio>
                  </Link>
                </BoxItem>
              </Col>
            ))}
          </Row>
        ) : (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </Container>

    </Base>
  );
};

export default LibraryList;

const BoxItem =  styled.div`
  position: relative;
`

const AspectRatio =  styled.div`
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    padding-bottom: 80%;
    display: block;
    height: 0;
    background-color: #eee;
  }
  
  &:hover {
    img {
      transform: scale(1.1);    
    }
  }
  
  img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    transition: all .6s ease;
  }
  
  .meta-info{
    position: absolute;
    bottom: 0;
    z-index: 2;
    left: 0;
    right: 0;
    padding: 5px;
    top: 0;
    display: flex;
    flex-direction: column-reverse;
    background: linear-gradient(0deg, rgba(0, 0, 0, .50), transparent);
  }
  
  h3{
    font-size: 18px;
    color: #ffffff;
    font-weight: normal;
    margin: 0;
  }
  
`
