import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import api from './services/api'
import { Spinner } from 'reactstrap';
import ReactNotification from 'react-notifications-component';
import Routes from './routes';
import store from './store';
import userPlaceholder from './assets/images/vaccinar_placeholder.png'


function App() {
  // const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    api.get('/users/me').then(response => {
      localStorage.setItem(`user-endpoint`, btoa(`/users/${response.data.id}`));
      localStorage.setItem(`user-id`, btoa(response.data.id));
      localStorage.setItem(`user-name`, btoa(response.data.name));
      localStorage.setItem(`user-email`, btoa(response.data.email));
      localStorage.setItem(`user-roles`, btoa(response.data.roles));
      localStorage.setItem(`user-photo`, btoa(response.data.hasOwnProperty('profile') && response.data.profile.hasOwnProperty('avatar') && response.data.profile.avatar.file != null ? response.data.profile.avatar.file.urlPublica : userPlaceholder));
      setLoading(true);

    }).catch(error => {
      console.error('Erro ao armazenar dados de usuario no local', error)
    });
  }, []);


  return (
    <Provider store={store}>
      {!loading ? (
        <div className="mt-30 mb-30 text-center">
          <Spinner />
        </div>
      ) : (
          <>
            <ReactNotification />
            <Routes />
          </>
        )}
    </Provider>

  );
}

export default App;
