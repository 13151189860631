import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FaqItem = styled.div`
    display: flex;
    flex-direction: column;
    
`;
export const Faqimage = styled.div`
    position: relative;
    padding-bottom: 58%;
`;
export const InfoFaq = styled.div`
    display:flex;
    padding: 10px 0px;
    justify-content: space-between;
    font-size: 12px;
    border-bottom: 1px solid #8591A526;
    margin: 0 15px;
`;
export const TextContent = styled.div`
    position:absolute!important; 
    margin: 15px 0px;
`;
export const Stars = styled.div`
 
`;
export const Icon = styled.i`
    margin-right:3px;
`;
export const Duration = styled.p`
    margin:0 0 5px;
`;
export const Views = styled.p`
    margin:0 0 5px;
`;

export const DadosFaq = styled.div`
    padding: 0px!important;
    margin:0px;
    height:0px;
    flex: 0 0 0!important;
    background-color:#fff;
    &.active{
        height: 100%!important;
    }
    
`;
export const ContentFaq = styled.div`
    display:flex;
    background-color: #E5231A;
    padding:20px
    align-items:start;
    justify-content:start;
    & h3{
        font-size: 2em;
        font-weight: 100;
    }
    & p{
        font-weight: 300;
        overflow: hidden;
        opacity: 0;
    }
    & a{
        color:#fff;
        font-size: 20px;
    }
    & .faq-box{
        &::-webkit-scrollbar {
            display:none;
          }
          &::-webkit-scrollbar-track {
            display:none;
          }
           
          &::-webkit-scrollbar-thumb {
              display:none;
          }
        @media(min-width:992px){
            overflow: auto;
            max-height: 322px;
            height: 100%;
            flex: none;
            display: block;
            
        } 
         @media(min-width:1200px){
            overflow: auto;
            max-height: 396px;
            height: 100%;
            flex: none;
            display: block;
        }
    }
    
`;
export const Photo = styled.div`
    display: block;
    width: 70px;
    height: 70px;
    overflow: hidden;
    position: relative;
    margin-right: 5px;
`;
export const UserImg = styled.img`
    position: absolute;
    left: 0;
    object-fit: cover;
`;
export const Autor = styled.p`
    margin: 0;
    font-size: 14px;
    text-align: left;
    color: #000;
    font-weight: bold;
    margin-top: 10px;
`;
export const TitleQuestion = styled.div`
    margin: 0;
    font-size: 16px;
    text-align: left;
    color: #000;
    display: flex;
    justify-content: space-between;
    padding: 0px;
    align-items: center;
    font-weight:bold;
    &.active{
        background-color: #f3f3f3;
    }
    & i{
        height: 100%;
        background-color: #E5231A;
        color: #fff;
        font-size: 22px;
        padding: 10px 15px;
    }
    & button{
        border: none;
        padding: 0;
    }

`;
export const TitleFaq = styled.h4`
    position: absolute;
    margin: 0;
    font-size: 16px;
    text-align: left;
    color: #fff;
    font-weight: 100;
    margin-bottom: 25px;
    background-color: #E5231A;
    padding: 20px;
    z-index:5;
`;
export const DescriptionFaq = styled.p`
    font-weight: 100;
    text-align: left;
    font-size: 15px;
    /* font-style: italic; */
    margin-bottom: 25px;
    padding: 15px;
`;
export const FaqLink = styled(Link)`
    text-align: left;
    color #E5231A;
    display:block;
    font-size:22px;
    
`;
export const BlockSection = styled.div`
    margin: 40px 0;
`;
export const LabelButtom = styled.label`
    cursor:pointer;    
    & i{
      transition:ease all 300ms;  
    }
    &:hover i{
        background-color:#252527;
    }
`;
export const BoxImageStyle = styled.div`
   
`;
export const Checkbox = styled.input`
    display:none;
   &:checked + div{
        height: 100%!important;
       
   }
`;
export const ImageBg = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
export const Box = styled.div`
    position: relative;
    padding-bottom: 100%;
    height: 0;
    overflow: hidden;
    width:100%;
`;
export const TitleFaqs = styled.span`
    background-color: #252527;
    color: #fff;
    padding: 25px 15px;
    margin: 0px 0;
    
`;
