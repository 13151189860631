import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'reactstrap';
import {
  Banner,
  BannerLink,
  DescriptionBanner,
  TitleBanner,
  BannerItem,
} from './style';

const BannerImg = (props) => {
  return (
    <Banner className="Banner-item">
      <img src={props.image} alt="banner" />
      <Container>
        <BannerItem className="legend-custom">
          <TitleBanner title={props.title}>{props.title}</TitleBanner>
          <DescriptionBanner title={props.description}>
            <spam dangerouslySetInnerHTML={{__html: props.description}} />
          </DescriptionBanner>
          <BannerLink to={props.url}>
            <i className="far fa-arrow-alt-circle-right" />
          </BannerLink>
        </BannerItem>
        <div className="controls" />
      </Container>
    </Banner>
  );
};
BannerImg.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default BannerImg;
