import React from 'react';
import Script from 'react-load-script'
import api from '../../services/api';

const SambaVideoModal = ({ reference }) => {
  const idPlayer = 'modalPlayer'

  const getSettingMediaKey = async () => {
    try {
      const response = await api.get("/settings/sambatech_player_key")
      return response.data.value
    } catch (e) {
      return null
    }
  }

  const clearPlayer = () => {
    const item = window.document.getElementById(idPlayer)
    if (item) {
      item.innerHTML = ''
    }
  }

  const eventListener = async (player) => {
    switch (player.event) {
      case 'onListen': break
      case 'onFinish': break
      default: break
    }
  }

  const init = async () => {
    const playerKey = await getSettingMediaKey()
    clearPlayer()
    if (playerKey) {
      new window.SambaPlayer(idPlayer, {
        ph: playerKey,
        m: reference,
        playerParams: {
          enableShare: false
        },
        eventListener: {
          '*': eventListener
        }
      })
    }

  }

  return (
    <>
      <Script url="https://player.sambatech.com.br/v3/samba.player.api.js" onLoad={init} />
      <div className="embed-responsive embed-responsive-16by9">
        <div className="embed-responsive-item" id={idPlayer}/>
      </div>
    </>
  );
};

export default SambaVideoModal;
