import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const Footer = styled.footer`
  background: transparent;
  padding: 20px 0;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 40px;
`;

export const Image = styled.img`
  width: 150px;
`;

export const Text = styled.p`
    text-align: right;
    color: #000;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    margin:0;
    @media(max-width:991px){
      margin-top: 10px;
    }
`;
export const TextLink = styled(Link)`
  text-align: right;
  color: #000;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  &:hover{
    color: #E5231A;
    opacity: 0.85;
  }
  &:before {
      content: "";
      width: 1px;
      background-color: #8591A5;
      height: 10px;
      display: block;
      position: relative;
      left: -10px;
  }
  @media(max-width:991px){
    &:nth-of-type(1):before {
        display: none;
    }
  }
  
`;