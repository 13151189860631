import React, { useState, useEffect } from 'react';
import Base from '../../../components/Theme/base';
import CourseItem from '../../../components/Home/Cursos/Distancia/CourseItem';
import { CourseBox, TrilhaStyle } from './styles';
import CursoPage from '../../../components/Cursos/Online';
import { Container, Col, Row } from 'reactstrap';
import api from '../../../services/api';
import userPlaceholder from '../../../assets/images/user_placeholder.png'
import Slider from "react-slick";
import getCurrentUser from '../../../utils/getCurrentUser';

const TrilhaInterna = () => {

  const [trilha, setTrilha] = useState({});




  useEffect(() => {

    const data = [];
    api.get(`/lms${window.location.pathname}`).then((response) => {
      response.data.trails_courses.forEach(res => {
        let lessons = [];
        if (res.course.lessons.length > 0) {//listagem de videos
          res.course.lessons.forEach(item => {
            let videos = [];
            let material = [];

            if (item.lesson_contents.length > 0) {
              let contents = item.lesson_contents || [];

              contents.forEach((video, i) => {

                api.get(`/general/watcheds?content_iri=/lms/lesson_contents/${video.id}`)
                  .then((progress) => {
                    videos.push({
                      lessonContent: video.id,
                      type: video.type || '',
                      position: video.position || 0,
                      watched_id: progress.data.length > 0 ? progress.data[0].id : null,
                      urlPublica: video.file !== null ? video.file.urlPublica : '',
                      title: video.title,
                      contentType: '',
                      samba_media_reference: video.samba_media_reference !== null ? video.samba_media_reference : '',
                      vimeo_media_reference: video.vimeo_media_reference !== null ? video.vimeo_media_reference : ''
                    });
                  })
                  .catch((erro) => {
                    console.error(erro);
                  })

              })
            }
            if (item.lesson_support_materials.length > 0) {//listagem de materiais
              let suport_material_array = item.lesson_support_materials ? item.lesson_support_materials : [];

              suport_material_array.forEach((suport_material) => {
                let suport_material_item = suport_material.support_material;
                if (suport_material_item.support_material_contents[0].file != null) {
                  material.push({
                    title: suport_material_item.title,
                    url: suport_material_item.support_material_contents[0].file.urlPublica,
                  });
                }
              })
            }

            lessons.push({
              _id: `/lms/lessons/${item.id}`,
              id: item.id,
              position: item && item.position || 0,
              title: item.title,
              lesson_stats: item.lesson_stats,
              progress: item.lesson_stats.length > 0 && item.lesson_stats.filter((lesson_stat) => lesson_stat.user_iri === getCurrentUser()).length > 0 ? item.lesson_stats.filter((lesson_stat) => lesson_stat.user_iri === getCurrentUser())[0].progress : 0,
              descricao: item.description,
              course_iri: "/lms/courses/" + res.course.id,
              course_id: res.course.id,
              complemento: material,
              videoaula: videos,
              exams: item.lesson_exams || []
            }
            )
          })
        }

        var course = {

          _id: `/lms/courses/${res.course.id}`,
          id: res.id,
          image: {
            urlPublica: res.course.image !== null ? res.course.image.urlPublica : userPlaceholder
          },
          nota: 5,
          course_stats: res.course.course_stats,
          progressoTotal: res.course.course_stats.length > 0 ? res.course.course_stats[0].progress : 0,
          duration: res.course.duration,
          data: res.course.created_at,
          views: res.course.views,
          photo: res.course.hasOwnProperty('profile') && res.course.created_by.profile.hasOwnProperty('avatar') && res.course.created_by.profile.avatar.file != null ? res.course.reated_by.profile.avatar.file.urlPublica : userPlaceholder,
          autor: res.course.hasOwnProperty('profile') && res.course.created_by.profile.hasOwnProperty('avatar') && res.course.created_by.name !== undefined ? res.course.created_by.name : "",
          titulo: res.course.title,
          description: res.course.summary,
          url: `/trails/${response.data.id}`,
          aulas: lessons,
          exams: res.course.course_exams.length > 0 ? res.course.course_exams : []
        };
        data.push(course);

      })
      console.log(Object.assign(trilha, {
        titulo: response.data.title,
        cursos: data
      }))
      setTrilha(Object.assign(trilha, {
        titulo: response.data.title,
        cursos: data
      }));
      setActive(prevState => prevState = !prevState);

    })
      .catch((err) => {
        console.error('Erro ao buscar Cursos: ', err);
      })
    return () => {
      setTrilha([]);
    };
  }, []);


  const [active, setActive] = useState(false);
  const [filter] = useState('');



  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  function expand(e) {
    setActive(prevState => prevState = !prevState);
  }
  var cursoAtual = 0;
  if (trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0) {
    trilha.cursos.map((curso, i) => curso.course_stats.length > 0 && curso.course_stats[0].progress === 100 ? cursoAtual = i + 1 : '')
    if (trilha.cursos.length === cursoAtual) cursoAtual = 0
  }

  return (
    <Base>
      <Container className="p-0">
        <TrilhaStyle>
          <Row className="py-3 pl-0 metaTrilha">
            <Col xs="12" lg="3" className="p-lg-0 d-flex align-items-center">
              {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 &&
                <h4 className="m-0">Trilha {trilha.titulo} | {trilha.cursos.length} cursos</h4>}
            </Col>
            {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 &&
              <Col xs="12" lg="6" className='mt-3 mt-lg-0 timeLine'>
                {trilha.cursos.map((curso, i) => {
                  return (active ?
                    <span key={`trilha-coure-${i}`} className={`trilha  ${curso.progressoTotal === 100 ? 'completo' : 'incompleto'}`} ><i className="far fa-check-square" /></span> : ''
                  )
                }
                )}
              </Col>
            }

            <Col xs="12" lg="3" className="mt-5 mt-lg-0 p-lg-0">
              <button onClick={expand} className="m-0 col-12 LoadMoreButtom">Ver detalhes<i className="fal fa-plus-circle ml-2 font-22" /></button>
            </Col>
            <Col xs="12" className={`CourseBlock mt-4 mb-4 pt-5 px-0 ${!active ? '' : 'd-none'}`}>
              {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 && (
                <Slider {...settings}>
                  {trilha.cursos.map((course, j) => {
                    return (
                      filter === '' || course.titulo.toLowerCase().indexOf(filter.toLowerCase()) > -1
                        ? <CourseBox key={`courseItem-${j}`} >
                          <div className="timeLine my-2">
                            <span className={`trilha ${cursoAtual === j ? 'atual' : ''} ${course.progressoTotal === 100 ? 'completo' : 'incompleto'}`}><i className="far fa-check-square" /></span>
                          </div>
                          <div className="p-3 p-md-0">
                            <CourseItem situation={`${course.progressoTotal === 100 ? 'concluido-' : ''}${cursoAtual === j && course.progressoTotal !== 100 ? 'atual' : 'locked'}`} course={course} />
                          </div>

                        </CourseBox> : '')
                  }

                  )}
                </Slider>
              )}
            </Col>
          </Row>
        </TrilhaStyle>
      </Container>
      {/* <Title value="Trilhas"/> */}
      <Container className="p-0">
        {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 &&
          <CursoPage course={trilha.cursos[cursoAtual]} />}
      </Container>
    </Base>
  )

};
export default TrilhaInterna;
