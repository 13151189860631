import React, { useState } from 'react';
import Script from 'react-load-script';
import api from '../../services/api';

const SambaVideoShow = ({ reference }) => {
    const [playKey, setPlayKey] = useState(null);
    let sambaPlayer = undefined;
    const initSambaPlayer = async () => {
        try {
            const response = await api.get("/settings/sambatech_player_key");
            setPlayKey(response.data.value || null);
        } catch (error) {
            console.error("SambaVideoShow.initSambaPlayer: ", error);
        }
    }
    const eventsListener = (player) => {
        const { event } = player;

        switch (event) {
            case "onStart":
                window.sambaPlayer.setVolume(100);
                window.sambaPlayer.unmute();
                break;
            case 'onLoad':
                break;
            case 'onListen':
                break;
            case 'onPause':
                break;
            case 'onResume':
                break;
            case 'onSeek':
                break;
            case 'onCuepoint':
                break;
            case 'onMediaView':
                break;
            case 'onProgress':
                break;
            case 'onFinish':
                break;
            case 'onError':
                break;
        }
    }

    if (playKey) {
        window.sambaPlayer = new window.SambaPlayer("player", {
            ph: playKey,
            m: reference,
            playerParams: {
                enabledShare: false,
                autoStart: true,
                html5: true,
                startOutput: "480p",
            },
            events: {
                "*": eventsListener,
            }
        })
    }

    return (
        <>
            <Script
                url="https://player.sambatech.com.br/v3/samba.player.api.js"
                onLoad={initSambaPlayer}
            />
            <div className="embed-responsive embed-responsive-16by9 mb-3">
                <div className="embed-responsive-item" id="player" />
            </div>
        </>
    );
}

export default SambaVideoShow;