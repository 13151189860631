import styled from 'styled-components'

export const Grid = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 30px;
`

export const GridNewLine = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  &.even {
    background-color: #f2f2f2;
  }
  &:hover:not(.no-hover){
    background-color: #eee;
  }
  > div, a {
    display: block;
    cursor: pointer;
    color: #313131;
    &:hover {
      text-decoration: none;
      color: #e5231a;
    }
    i {
      margin-right: 5px;
    }
  }
`
