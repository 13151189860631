import React,{memo} from 'react';
import SambaVideo from "./SambaVideo";
import VideoFile from './VideoFile';
import FilePDF from './FilePDF';


const ContentMedia = ({video,...props}) => {
  const Medias = () => {
    switch (video.type) {
      case 'SAMBA_MEDIA':
        return (
          <SambaVideo
            contentType={video.contentType}
            refreshProgress={props.refreshProgress}
            lesson_content_iri={video.watched_id}
            id={video.lessonContent}
            reference={video.samba_media_reference}
          />
        );
        break;
      case 'FILE':
        if (video.contentType === 'image/jpeg') {
          return <img className="img-fluid" src={video.urlPublica} />;
        } else if (video.contentType === 'application/pdf') {
          return <FilePDF url={video.urlPublica} />;
        } else if (video.contentType === 'video/mp4') {
          return <VideoFile url={video.urlPublica} />;
        }

        break;
      default:
        return video.file ? video.file.urlPublica : '';
        break;
    }
     
  };

  return (
    <div className="medias">
      <Medias/>
    </div>
  );
};

export default memo(ContentMedia);
