import React, { useState, useEffect } from 'react';
import {
	RatingStarsInner,
	RatingStarsOuter,
	LabelStars
} from './styles';

const RatingsShow = ({ ratings }) => {
	const total = 5;
	const rounded = `${Math.round((ratings / total) * 100)}%`;

	return (
		<div>
			<RatingStarsOuter>
				<RatingStarsInner stars={rounded}/>
			</RatingStarsOuter>
			<LabelStars>
				&nbsp;{ratings}/{total}
			</LabelStars>
		</div>
	);
};

export default RatingsShow;