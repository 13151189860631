import React, { useState } from 'react';
import { Row } from 'reactstrap';
import { BlockSection, BannerImage, TextContent, TitleSobre, BoxSobre } from './style';
import { Carousel } from 'react-responsive-carousel';

const BlockSobre = ({ banners }) => {

	const [bannerList] = useState(banners.length > 0 ? banners : []);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [showNav, setShowNav] = useState(banners.length > 1 ? true : false);

	const handlePrev = () => {
		setCurrentSlide(prev => prev ? prev - 1 : 0);
	}

	const handleNext = () => {
		setCurrentSlide(prev => prev < banners.length - 1 ? prev + 1 : 0)
	}

	const updateCurrentSlide = (index) => {
		if (currentSlide !== index) {
			setCurrentSlide(index)
		}
	}

	return (
		<BlockSection>
			<Carousel
				selectedItem={currentSlide}
				onChange={updateCurrentSlide}
				showIndicators={false}
				showStatus={false}
				showThumbs={false}
			>
				{bannerList.length > 0 &&
					bannerList.map((banner, i) => (
						<>
							<Row key={`banner-${i}`}>
								<BannerImage>
									<img src={banner.file.urlPublica} alt="banner" />
								</BannerImage>
								{banner.active && banner.title && (
									<>
										<TextContent className="col-3 d-flex align-items-start flex-column">
											<TitleSobre>VACCINAR</TitleSobre>
											<BoxSobre>
												<h3>{banner.title}</h3>
											</BoxSobre>
										</TextContent>
									</>)
								}

							</Row>
						</>
					))
				}
			</Carousel>
			<div className={`container slider-controls ${!showNav ? 'd-none' : ''}`}>
				<button onClick={e => handlePrev()}><i className="far fa-arrow-alt-circle-left" /></button>
				<button onClick={e => handleNext()}><i className="far fa-arrow-alt-circle-right" /></button>
			</div>
		</BlockSection>
	);
};


export default BlockSobre;
