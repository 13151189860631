import styled from 'styled-components';

export const StarsStyle = styled.div`
    font-size: 0.8em;
    display: flex;
    align-items: center;
    & p{
        font-style:italic;
        display: flex;
        align-items: center;
        margin:0 0px 0 3px 
    }
`;
export const Professor = styled.div`
    display:flex;
`;
export const ImgProfessor = styled.div`
    border-radius: 50%;
    display: block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    position: relative;
    margin-right:5px;
`;
export const UserImg = styled.img`
    position: absolute;
    left: 0;
    height:100%;
    object-fit: cover;
`;
export const NomeProfessor = styled.p`
    margin: 0;
    font-size: 13px;
    text-align: left;
    color: #000;
`;
export const DataCurso = styled.p`
    margin: 0;
    font-size: 13px;
    text-align: left;
    color: #000;

`;
export const Icon = styled.i`
    margin-right:3px;
`;
export const Views = styled.p`
    margin:0 0 5px;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    & i{
        position: relative;
        top: 1px;
    }
`;
export const ActionsStyle = styled.div`
    border: 1px solid #e5e5e5;
    border-left: 0;
    border-right: 0;
    b{
        font-weight:bold;
    }
`;
export const AvalieStyle = styled.div`
    
    .far,& .fas{
        color: #585857;
        font-size: 1.3em;
        cursor: pointer;
    }

    .far:hover {
        font-weight: 900;
    }
    & i{
        position: relative;
        top: 1px;
    }
`;
export const FavortitosStyle = styled.div`
    cursor:pointer;
    transition:ease all 300ms;
    & .fas{
        color:#f44336;
    }
    &:hover{
        text-decoration:none;
        color: #E5231A;
        i{
            zoom: 1.5;
            font-weight:bold;
        }
    }
`;
export const ProgressCurso = styled.div`
    width: 250px;
`;
export const RowAula = styled.div`
    background-color: #2a2a2b;
    justify-content: space-between;
    margin-bottom:10px
    transition:ease all 400ms;
    &.expand {
        background-color: #2a2a2b;
        .progressTitle{
            color:#fff;
        }
        .title-aula{
            color: #fff
        }
        .title-type{
            color: #fff
        }
    }
`;
export const NumberAula = styled.span`
    background-color: #2a2a2b;
    color: #fff;
    padding: 10px;
     
`;
export const TitleAula = styled.span`
		flex-grow: 1;
		color: white;
    padding-left: 30px
    display:flex;
    align-items:center;
    border-right: 1px solid #b3b3b3;
    & h4{
        margin:0;
    }
`;
export const ProgressAula = styled.span`
    padding:0 15px;
    display:flex;
    color: #fff;
    align-items:center; 
`;
export const LabelButtom = styled.label`
    text-align: left;
    background-color: #E5231A;
    color:#fff;
    font-size:22px;
    cursor:pointer;
    padding:10px 0!important;
    @media(min-width:991px){
        max-width: 5%!important;
    }
    

`;
export const BlockSection = styled.div` 
    .input-expand:checked + .expand-content {
        height: 100%;
    }

    .expand-content {
        height: 0;
        overflow: hidden;
        h5{
            font-weight:normal;
        }
    }
`;
export const LikeBar = styled.div`
    border-bottom: 1px solid #e5e5e5
    padding:10px 0;
    
    
    & button{
        background-color: transparent;
        border: none;
        font-size: 25px;
        &:hover {
            zoom: 1.2;
        }
    }
    & i{
        color:#585857;
    }
`;
export const BoxNextVideos = styled.div`
    max-height: 366px;
    overflow-y: auto;
    padding-right: 45px;
    overflow-x: hidden;
    /* width */
    &::-webkit-scrollbar {
    width: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
     background: #f1f1f1;
     border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #2a2a2b;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #2a2a2b;
    }
`;
export const DescriptionSection = styled.div`
    font-family: "HelveticaLTStd";
    font-weight: 300;
    .anexos{
        margin:30px 0 0 0;
    }    
    a{
        color: #000;
        white-space: nowrap;
        width: 18em;
        overflow: hidden;
        text-overflow: clip;
        text-decoration: underline;
        font-weight: 300;
        display: flex;
        font-size: 0.9em;
        align-items: center;
        margin: 15px 0;
        font-family: "HelveticaLTStd";
    }
    .description{
        padding-bottom:30px;
        border-bottom: 1px solid #e5e5e5;
    }
    & .list-questions{
        max-height: 600px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 30px;
            /* width */
            &::-webkit-scrollbar {
            width: 7px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #2a2a2b;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #2a2a2b;
            }
            & input[type="checkbox"]:checked + .sub-resp {
                height: 100%;
            }
            & .sub-resp {
                height: 0;
                overflow: hidden;
                display: block;
                width: 100%;
                &.active{
                    height:100%;
                    & .newQuestion{
                        display:flex!important;
                    }
                }
                & .newQuestion{
                    display:none!important;
                }
            }
    }

`;
export const FeaturedBoxVideo = styled.div`
    height: 0;
    display: flex;
    align-items: center;
    padding-bottom: 69%;
    position: relative;
    cursor: pointer;
    width: 100%;
    &:before {
        font-size: 15px;
        content: "\f144";
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.53);
        color: #fff;
        font-family: "Font Awesome 5 Pro";
        z-index: 9999;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        cursor: pointer;
        top:0;
    }
    & video{
        position:absolute;
    }
    & .embed-responsive{
        position: absolute;
        height: 100%;
        top:0;
    }
    
    
`;
export const ProvaStyle = styled.div`
    background-color: #252527;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 30px;
    margin:40px 0 100px;
    & h3{
        color:#fff;
        margin:0 0px 0 0 ;
    }
    & a{
        background-color: #2a2a2b;
        padding: 10px 60px;
        font-weight: bold;
        color: #fff;
        &:hover{
            color: #2a2a2b;
            text-decoration: none;
            background-color: #fff;
        }
    }
`;
export const VideoStyle = styled.video`
    width: 100%;
    
    display: block;
    object-fit: cover;
    top: 0;
`;
export const ContentStyle = styled.div`
    background-color:#fff;
    border:1px solid #b3b3b3;
    border-top:0;
    display:flex;
    #featuredPDFView{
        width:100%;
        min-height:400px;
    }
`;
export const TitleVideo = styled.h5`
    // background-color:#fff;
    
`;
export const ModalExamSytled = styled.div`
		position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.82);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    .exam-style{    
				background-color: #fff;
				overflow: auto;
				height: 95vh;
				border-radius: 8px;
        padding: 40px 40px !important;
           & button#next-question {
            right: 0;
            position: absolute;
          }
          
          & button#prev-question {
            bottom: -44px;
            position: absolute;
          }
          & button#submitAnswers {
            width: 100%;
            max-width: 100%;
            top: 60px;
            background-color: #2a2a2b;
          }
         & button.close-button {
            border: none;
          }
          & .slick-disabled{
              opacity:0;
              visibility:hidden;
          }
    }
    
`;
export const NextVideo = styled.div`
    border-bottom: 1px solid #e5e5e5
    cursor: pointer;
    transition:ease all 300ms;
    .video-line{
        transition: ease all 300ms;
        &:hover{
            background-color: #252527c2;
            color: #fff; 
        }
        & video{
            height: 100%;
        }
    }
`;
export const QuestionStyle = styled.div`
    & .headerQuestion{
        span{
            font-size: 12px;
            color: #151515;
        }
    }
    & .questionContent{
        font-size: 14px;
        & input{
            background-color: transparent;
            border: none!important;
            width: 100%;
        }
        & textarea{
            border:none;
            width:100%;
            background-color:transparent;
            ::placeholder {
                color: #777777
            }

        }
        .buttonSend {
            visibility: collapse;
            opacity: 0;
            position: absolute;
            right: 26px;
            bottom: 12px;
            background-color:  #E5231A;
            border: none;
            color: #fff;
            border-radius: 3px;
            transition: ease all 300ms;
            border: 1px solid   #E5231A;
        }
        
        button.buttonSend:hover {
            border: 1px solid   #E5231A;
            background-color: transparent;
            color:  #E5231A;
        }
        
        textarea:focus + .buttonSend {
            opacity: 1;
            visibility:visible;
            
        }
    }
    & .content-question{
        background-color:#eeeeee;
        padding: 20px;
        &:before {
            content: "";
            display: block;
            border-right: 20px solid #eeeeee;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            position: absolute;
            left: 10px;
        }
    }
    & .box-question{
        padding-left: 30px;
    }

`;
export const UserPhotoStyle = styled.div`
    padding-top: 10px;
    & .user-img {
        border-radius: 50%;
        position: relative;
        height: 100%;
        width: 42px;
        display: block;
        height: 42px;
        overflow: hidden;
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
`;
export const QuestionActions = styled.div`
    background-color:#fff;
    label, button{
        cursor:pointer;
        background-color: transparent;
        border: none;
        font-weight:bold;
    }
    .btn-resp{
        color:#2a2a2b;
        margin-right:30px;
    }
    & .fa-sort-down:before{
        position: relative;
        top: -3px;
        color:#2a2a2b;
    }
`;

export const ViewedCheckbox = styled.div`
    color:#666666;
    cursor:pointer;
    transition:ease all 300ms;
    &:hover{
        text-decoration:none;
        i {
            zoom: 1.2;
        }
    }
`;

export const RatingStarsInner = styled.span`
	position: absolute;
	padding: 5px 0px;
  top: 0;
  left: 0;
  white-space: nowrap;
	overflow: hidden;
  width: ${(props) => (props.stars ? props.stars : 0)};
  &:before {
    font-weight: bold;
  }
`;

export const RatingStarsOuter = styled.div`
	display: inline-block;
  position: relative;
  font-family: 'Font Awesome 5 Pro', sans-serif;
	font-size: 12px;
	padding: 5px 0px;
  &:before,
  ${RatingStarsInner}:before {
    content: '\f005  \f005  \f005  \f005  \f005';
    color: #29292b;
  }
`;

export const LabelStars = styled.span`
	font-style: italic;
	font-size: 12px;
	padding: 5px 0px;
`;
