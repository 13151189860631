import React, { useEffect, useState } from 'react';
import Base from '../../components/Theme/base'
import { Col, Container, Row, Spinner } from 'reactstrap'
import Title from '../../components/Theme/Title'
import api from '../../services/api'
import { Grid, GridNewLine } from './styled'
import { useModalBox } from '../../components/modal/context'
import SambaVideoModal from '../../components/ContentMedia/SambaVideoModal'

const LibraryShow = ({ match }) => {
  const { id } = match.params
  const [loading, setLoading] = useState(true)
  const [contents, setContents] = useState([])
  const { setOpen, setContent } = useModalBox()

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/lms/support_materials/library?course_id=${id}`)
        const data = response.data || []

        setContents(data)
        setLoading(false)
      } catch (_e) {
        setLoading(false)
      }
    })()
  }, [id])

  const handleModal = (reference) => {
    setOpen(true)
    setContent(<SambaVideoModal reference={reference} />)
  }

  const hasContent = (content) => {
    return content.support_material_contents.length &&
      content.support_material_contents.filter(material =>
        (material.type === 'FILE' && material.file) ||
        (material.type === 'SAMBA_MEDIA' && material.samba_media_reference)
      ).length;
  }

  return (
    <Base>

      <Title value="Materiais de apoio" />

      <Container>
        {!loading ? (
          <Row>
            {contents.map(content => (
              <Col xs={12} key={content.id}>
                <h3>{content.title}</h3>
                <Grid>
                  {hasContent(content) ? content.support_material_contents.map((material, index) => (
                    <GridNewLine key={material.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                      {(material.type === 'FILE' && material.file) && (
                        <a href={material.file.urlPublica} target="_blank" rel="noreferrer noopener" download>
                          <i className="fal fa-paperclip" />
                          <span>{material.title}</span>
                        </a>
                      )}
                      {material.type === 'SAMBA_MEDIA' && material.samba_media_reference && (
                        <div onClick={() => handleModal(material.samba_media_reference)}>
                          <i className="fal fa-video" />
                          <span>{material.title}</span>
                        </div>
                      )}
                    </GridNewLine>
                  )) : (
                    <GridNewLine className="no-hover">
                      Nenhum material encontrado
                    </GridNewLine>
                  )}
                </Grid>

              </Col>
            ))}
          </Row>
        ) : (
          <div className="text-center">
            <Spinner />
          </div>
        )}
      </Container>
    </Base>
  );
};

export default LibraryShow;
