import React, { useState, useEffect } from 'react';
import CourseItem from './CourseItem';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BlockSection, TextContent, TitleSobre, BoxSobre } from './style';
import api from '../../../../services/api';
import photo from '../../../../assets/images/vaccinar_placeholder.png';

const BlockCursosPresenciais = (props) => {

  const [courses, setCourses] = useState([]);

  const coursesContent = props.content || [];
  useEffect(() => {

    const data = [];

    if (coursesContent.length > 0) {
      coursesContent.forEach(element => {
        if (element.active) {
          data.push({
            _id: `/lms/courses/${element.id}`,
            id: element.id,
            image: {
              file: {
                urlPublica: photo
              }
            },
            nota: 5,
            duration: element.duration || '',
            data: element.published_at || '',
            views: '845',
            photo: photo,
            titulo: element.title || '',
            description: element.summary || '',
            url: `/presencial/lms/classroom_courses/${element.id}`
          })
        }
      })

    }
    setCourses(data);




    return () => {
      setCourses([]);
    };
  }, []);

  return (
    <BlockSection className="container CourseBlock p-0">
      {courses && courses.length > 0 &&
        <Row className="d-flex flex-wrap flex-column-reverse flex-lg-row">

          <Col xs="12" lg="9" className="p-0 d-flex flex-wrap">
            {courses.slice(0, 3).map((course, i) => <div key={`curso-${i}`} className="col-12 col-lg-4 pr-lg-4 pl-lg-0 pb-3 pb-lg-0 "> <CourseItem key={`courseItem${i}`} course={course} /></div>)}
          </Col>

          <TextContent className="col-12 col-lg-3 m-0 d-flex align-items-start flex-column">
            <TitleSobre>Cursos</TitleSobre>
            <BoxSobre className="h-100">
              <h3>{props.block.title ? props.block.title : "Cursos Presenciais"}</h3>
              <p>Ver agenda completa</p>
              <Link to='/presencial/lms/classroom_courses'><i className="far fa-arrow-alt-circle-right" /></Link>
            </BoxSobre>
          </TextContent>
        </Row>
      }
    </BlockSection>
  );
};


export default BlockCursosPresenciais;
