import React from 'react';
import MenuItem from './MenuItem';
import {
  PerfilPhoto,
  Dropdown,
  MenuItemLogin,
  LoginButtom,
  LoginIcon,
  UserButtons,
} from './styles';
import photo from '../../../assets/images/photo.png';

const LoginItem = (props) => {
  function logout() {
    window.location = `${process.env.REACT_APP_SIGNIN_URL}?logout=${btoa(
      document.location.origin,
    )}`;
  }
  const abreviate = (name) => {
    if (name.split(' ').length > 1) {
      let lastName = name.split(' ')[1];
      return name.split(' ')[0] + ' ' + lastName[0].toLocaleUpperCase() + '.';
    }
    return name;
  };
  return atob(localStorage.getItem(`user-name`)) != null ? (
    <LoginButtom className="logado d-flex align-items-center justify-content-lg-center justify-content-center flex-wrap h-100">
      <PerfilPhoto className="col-2 p-0">
        <div className="boxPhoto">
          <img src={atob(localStorage.getItem(`user-photo`))} alt="perfil" />
        </div>
      </PerfilPhoto>
      <UserButtons className="userButtons m-0 col-12 col-lg-8 pr-0">
        <span>
          <span>Bem vindo,</span>
          <span className="abreviateName">
            {atob(localStorage.getItem(`user-name`)).toLowerCase()}
          </span>
        </span>
        <Dropdown className="dropdownLogin ">
          <MenuItemLogin className="user-item-menu" to="/meusdados">
            <i className="fal fa-chevron-right mx-3"></i>Meus Dados
          </MenuItemLogin>
          <MenuItemLogin className="user-item-menu" to="/meuscursos">
            <i className="fal fa-chevron-right mx-3"></i>Meus Cursos
          </MenuItemLogin>
          <MenuItemLogin
            onClick={logout}
            className="user-item-menu pb-2"
            to="#">
            <i className="fal fa-chevron-right mx-3"></i>Sair
          </MenuItemLogin>
        </Dropdown>
        {/* <MenuItem className="user-item-menu" url="#" title="Login"/>
            <MenuItem className="user-item-menu" url="#" title="Cadastrar"/> */}
      </UserButtons>
    </LoginButtom>
  ) : (
    <LoginButtom className="d-flex align-items-center justify-content-lg-start justify-content-center flex-wrap h-100">
      <LoginIcon className="fas fa-user-circle" aria-hidden="true" />
      <UserButtons className="userButtons m-0 col-12 col-lg-10">
        <MenuItem className="user-item-menu" url="#" title="Login" />
        <MenuItem className="user-item-menu" url="#" title="Cadastrar" />
      </UserButtons>
    </LoginButtom>
  );
};

export default LoginItem;
