import React, { useEffect, useState, useCallback } from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { Container } from 'reactstrap';
import SambaVideoShow from '../../components/ContentMedia/SambaVideoShow';
import { loadLives } from '../../services/general/lives';

const Live = () => {

  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState({});

  const convertToUsDate = (date) => {
    const part1 = date.split("/");
    const part2 = part1[2].split(" ");
    const part3 = part2[1].split(":");

    const dateFormat = new Date(part2[0], part1[1] - 1, part1[0], part3[0], part3[1], part3[2]);
    return dateFormat.getTime();
  }

  const loadContents = useCallback(async () => {
    try {
      const response = await loadLives({ active: true });
      const { data } = response;

      const now = new Date().getTime();
      const dataFiltered = data.filter((item) =>
        convertToUsDate(item.started_at) <= now && convertToUsDate(item.ended_at) >= now
      );

      setContents(dataFiltered[0] || {});
      setLoading(false);

    } catch (error) {
      console.error("Live.loadContents", error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadContents();
    return () => {
      setLoading(true);
    }
  }, [loadContents]);

  if (loading) {
    return null;
  }

  return (
    <Base>
      <Title value="Live" />
      {Object.keys(contents).length ? (
        <Container className="p-0 mb-5 pb-5">
          {contents.type === "SAMBA_MEDIA" ? (<SambaVideoShow reference={contents.samba_media_reference} />) :
            <p>Nenhuma midia encontrada!</p>}
          <h3>{contents.title}</h3>
          {contents.description ? <div dangerouslySetInnerHTML={{ __html: contents.description }} /> : ""}
        </Container>
      ) : <p>Nenhuma live encontrada no momento!</p>}
    </Base>
  )
};
export default Live;
