import styled from 'styled-components';

export const BoxSobre = styled.div`    
    margin: 0px 0;
    background-color: #ffffff;
    color: #000;
    padding: 15px;
    border: 1px solid #E5231A;
    width: 100%;
    font-family: "HelveticaLTStd", sans-serif;
    & h3{
        font-size: 1.5em;
        font-weight: 100;
    }
    & p{
        font-weight: 300;
    }
    & a{
        color:#fff;
        font-size: 25px;
    }
    & i{
        font-size: 1.3em;
        color: #E5231A;
    }
`;
export const TitleSobre = styled.span`
    background-color: #585857;
    color: #fff;
    padding: 5px 15px;
    margin: 0px 0;
    width: 100%;
    font-size: 2.5em;
    font-family: "HelveticaLTStd", sans-serif;
    font-weight: 100;
`;
export const TextContent = styled.div`
    margin: 15px 0px;
    background: none;
    border: none;
    text-align: left;
    cursor:pointer;
    .BoxSobre {
      transition:ease all 300ms;  
    }
    
    &:hover .BoxSobre {
        background-color: #E5231A;
        color: #fff;
        i{
           color: #fff; 
        }
    }
    @media(max-width:991px){
        position:relative!important;
    }
`;
export const Cursos = styled.div`
    .btn-cert{
        font-size:15px;
    }
    .respCursos {
        max-height: 460px;
        overflow: auto;
             /* width */
            &::-webkit-scrollbar {
            width: 7px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #8591a5;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #8591a5;
            }
    }
`;
export const RespCursos = styled.div`
        max-height: 460px;
        overflow: auto;
             /* width */
            &::-webkit-scrollbar {
            width: 7px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #8591a5;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #8591a5;
            }
`;