import React, {useState} from 'react';
import {Carousel} from 'react-responsive-carousel';
import BannerImg from './bannerImg';
import PropTypes from 'prop-types';
import {set} from 'js-cookie';

const BannerCarousel = (banners) => {
  const [bannerList] = useState(
    banners.content.banners.length > 0 ? banners.content.banners : [],
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showNav, setShowNav] = useState(
    banners.content.banners.length > 1 ? true : false,
  );

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev ? prev - 1 : 0));
  };

  const handleNext = () => {
    setCurrentSlide((prev) =>
      prev < banners.content.banners.length - 1 ? prev + 1 : 0,
    );
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const comparador = (a, b) => {
    if (a.position > b.position) {
      return 1;
    }
    if (a.position < b.position) {
      return -1;
    }
    // a must be equal to b
    return 0;
  };

  return (
    <>
      <Carousel
        classname="Banner-Home"
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}>
        {bannerList.filter((item) => item && item.active).length > 0 &&
          bannerList
            .filter((item) => item && item.active)
            .sort(comparador)
            .map((banner, i) => (
              <BannerImg
                key={`banner-img-${i}`}
                image={banner.file.urlPublica}
                title={banner.title}
                description={banner.description}
                url={banner.url}
              />
            ))}
      </Carousel>
      <div className={`container slider-controls ${!showNav ? 'd-none' : ''}`}>
        <button onClick={(e) => handlePrev()}>
          <i className="far fa-arrow-alt-circle-left" />
        </button>
        <button onClick={(e) => handleNext()}>
          <i className="far fa-arrow-alt-circle-right" />
        </button>
      </div>
    </>
  );
};

BannerCarousel.propTypes = {
  banners: PropTypes.array,
};
export default BannerCarousel;
