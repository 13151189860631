import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { QuestionStyle, UserPhotoStyle } from './styles';
import api from '../../../services/api'
import { HandleRefreshContext } from './handleRefreshContext';
import ContainerLoading from '../../Theme/Loading'

const NewQuestion = ({ user, ...props }) => {
    const [description, setDescription] = useState('');
    const { handleRefreshComments } = useContext(HandleRefreshContext);
    const [loading, setLoading] = useState(false);
    function sendQuestion() {
        setLoading(false);
        api.post('/lms/course_questions',
            {
                "course": {
                    "id": props.course_id
                },
                "user_iri": atob(localStorage.getItem(`user-endpoint`)),
                "lesson": {
                    "id": props.lesson_id
                },
                "question": description,
                "answered": false
            }

        ).then((response) => {
            handleRefreshComments();
            setDescription('');
            setLoading(true);
        })
    }
    function sendAnswer() {
        setLoading(false);
        api.post('/lms/course_answers',
            {
                "course_question": {
                    "id": props.parent
                },
                "updated_by_iri": atob(localStorage.getItem(`user-endpoint`)),
                "answer": description
            }

        ).then((response) => {
            handleRefreshComments();
            setDescription('');
            setLoading(true);
        })
    }
    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
        }, 2000)
    })
    return (
        loading === false ? <ContainerLoading /> :
            <>
                <QuestionStyle className="row d-flex justify-content-between my-3 newQuestion">
                    <UserPhotoStyle className="col-2 col-md-1 pr-0">
                        <div className="user-img">
                            <img src={user.photo} alt={user.name} />
                        </div>
                    </UserPhotoStyle>
                    <div className="col-10 col-md-11 box-question">
                        <div className="content-question ">
                            {/* <Col lg="12" className="headerQuestion p-0 mb-3 d-flex justify-content-lg-between">
                        <h4>{.user.name}</h4>
                        <span>{pergunta.data} | {pergunta.hora}</span>
                    </Col> */}
                            <div className="questionContent">
                                <textarea type="text" onChange={e => setDescription(e.target.value)} placeholder="Escreva sua anotação..." value={description} />
                                <button onClick={props.answer ? sendAnswer : sendQuestion} className="buttonSend"><i className="fas fa-paper-plane"></i></button>
                            </div>
                        </div>


                    </div>


                </QuestionStyle>
            </>
    );
};
NewQuestion.propTypes = {
    user: PropTypes.object.isRequired,
    parent: PropTypes.number,
    lesson_id: PropTypes.number
}


export default NewQuestion;
