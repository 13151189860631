import styled from 'styled-components';

export const BlockSection = styled.div`
		margin: 40px 0;
    position:relative;
`;
export const TextContent = styled.div`
    position:absolute!important; 
    margin: 15px 0px;
    @media(max-width:991px){
        position:relative!important;
    }
`;
export const Background = styled.div`    
    z-index: -1;
    @media(max-width:991px){
        position:absolute!important;
        height:100%;
        & img{
            height:100%;
            width:100%;
            object-fit:cover;
        }
    }
`;
export const TitleSobre = styled.span`
    background-color: #E5231A;
    color: #fff;
    padding: 25px 15px;
    margin: 15px 0 0 15px;
`;
export const BoxSobre = styled.div`    
    margin: 0 0 0 15px;
    background-color: #eeeeee;
    color: #2a2b2d;
    padding: 15px;
    & h3{
        font-size: 2em;
				font-weight: 120;
    }
    & p{
        font-weight: 300;
    }
    & a{
        color:#49494b;
        font-size: 25px;
    }
    & a:hover{
        color:#49494b;
        opacity: 0.65;
    }
`;
export const BannerImage =styled.div`
height: 0;
padding-bottom: 40%;
  img {
		position: absolute;
		height:100%;
		width:100%;
		object-fit:cover;
		top: 0;
		left: 0;
	}  
`;
