import React, { useState, useEffect, useMemo } from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { FormStyle, RoundedPhoto, PerfilPhoto } from './styles';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import userPlaceholder from '../../assets/images/user_placeholder.png';
import api from '../../services/api';
import { Formik, Form, Field } from 'formik';
import { toSnakeCase } from '../../utils/toSnakeCase';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { changePasswordUser } from '../../services/endpoints';

const MeusDados = () => {
  const [load, setLoad] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [initialFieldValues, setInitialFieldValues] = useState([]);
  const [showErros, setShowErros] = useState(false);
  const phoneNumberMask = [
    '(',
    /[1-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  const cepNumberMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
  const [avatar, setAvatar] = useState(null);
  const [avatarUpdate, setAvatarUpdate] = useState(false);
  const [ufs, setUfs] = useState([]);
  const [fields, setFields] = useState({});

  const [user, setUser] = useState({
    avatar: '',
    nome: '',
    email: '',
    telefone: '',
    logradouro: '',
    cidade: '',
    numero: '',
    cep: '',
    bairro: '',
    uf: '',
    plainPassword: '',
    confirmPassword: '',
  });

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const preview = useMemo(() => {
    return avatar && typeof avatar === 'object'
      ? URL.createObjectURL(avatar)
      : avatar;
  }, [avatar, user.avatar]);

  useEffect(() => {
    api.get('/users/me').then((res) => {
      setAvatar(
        res.data.profile &&
          res.data.profile.avatar &&
          res.data.profile.avatar.file
          ? res.data.profile.avatar.file.urlPublica
          : userPlaceholder,
      );
      getFieldValues(res.data.profile);

      api
        .get(`/general/field_domain_values?match[field.title]=UF`)
        .then((response) => {
          setUfs(response.data);

          // id do estado atual
          const tempUf =
            res.data.profile && res.data.profile.uf
              ? res.data.profile.uf.value
              : '';
          const state = getIdStates(tempUf, response.data);

          setUser({
            avatar:
              res.data &&
                res.data.profile &&
                res.data.profile.avatar &&
                res.data.profile.avatar.file
                ? res.data.profile.avatar.file.urlPublica
                : null,
            nome:
              res.data && res.data.profile && res.data.profile.nome
                ? res.data.profile.nome.value
                : res.data.name
                  ? res.data.name
                  : '',
            email: res.data && res.data.email ? res.data.email : '',
            telefone:
              res.data && res.data.profile && res.data.profile.telefone
                ? res.data.profile.telefone.value
                : '',
            logradouro:
              res.data && res.data.profile && res.data.profile.logradouro
                ? res.data.profile.logradouro.value
                : '',
            cidade:
              res.data && res.data.profile && res.data.profile.cidade
                ? res.data.profile.cidade.value
                : '',
            numero:
              res.data && res.data.profile && res.data.profile.numero
                ? res.data.profile.numero.value
                : '',
            cep:
              res.data && res.data.profile && res.data.profile.cep
                ? res.data.profile.cep.value
                : '',
            bairro:
              res.data && res.data.profile && res.data.profile.bairro
                ? res.data.profile.bairro.value
                : '',
            uf: state || '',
          });
          setLoad(true);
        })
        .catch((err) => {
          setLoad(true);
          console.error(err);
        });
    });
  }, []);

  function getIdStates(state, ufs) {
    let id = '';
    ufs.map((uf) => {
      if (uf.value === state) id = uf.id;
    });
    return id;
  }

  function getFieldValues(profile = []) {
    const fields = {};
    const fieldsArray = [];

    api.get('/general/fields').then((resp) => {
      resp.data.forEach((item) => {
        if (Object.keys(user).includes(toSnakeCase(item.title))) {
          Object.assign(fields, {
            [toSnakeCase(item.title)]: {
              fieldId: item.id,
            },
          });
          fieldsArray.push(parseInt(item.id));
        }
      });
      getProfileFilds(fields, profile, fieldsArray);
    });
    setFields(fields);
    return fields;
  }

  function getProfileFilds(fields, profile, fieldsArray) {
    delete profile.user_id;

    Object.keys(profile).forEach((item) => {
      if (fieldsArray.includes(profile[item].field.id)) {
        if (fields[item] !== undefined) {
          Object.assign(fields[item], {
            value: profile[item].value,
            id: profile[item].id,
          });
        }
      }
    });
    setInitialFieldValues(fields);
  }
  const SignupSchema = Yup.object().shape({
    nome: Yup.string().required('Nome é Obrigatório'),
    email: Yup.string().email().required('Email Obrigatório'),
    telefone: Yup.string().required('Telefone Obrigatório'),
    logradouro: Yup.string().required('Endereço é Obrigatório'),
    cidade: Yup.string().required('Cidade é Obrigatório'),
    numero: Yup.number().required('Número é Obrigatório'),
    cep: Yup.string().required('CEP é Obrigatório'),
    bairro: Yup.string().required('Bairro é Obrigatório'),
    uf: Yup.string().required('Estado é Obrigatório'),
    plainPassword: Yup.string()
      .min(8, 'Senha com no mínimo 8 caracteres')
      .max(24, 'Senha com no máximo 24 caracteres')
      .matches(/[a-z]/, 'Pelo menos um caractere minúsculo')
      .matches(/[A-Z]/, 'Pelo menos um caractere maiúsculo')
      .matches(/[0-9]/, 'Pelo menos um número')
      .matches(
        /[@$!%*#?&.<>]/,
        'Pelo menos um caractere especial ex: @,$,!,%...',
      ),
    confirmPassword: Yup.string().when('plainPassword', {
      is: (val) => val && val.length >= 8,
      then: Yup.string()
        .oneOf([Yup.ref('plainPassword')], 'As senhas não são iguais')
        .required('Campo obrigatório'),
    }),
  });

  const updateUf = (field_domain_value_id, field_value_id) => {
    if (!field_value_id) {
      api
        .post(`/general/field_values`, {
          field: {
            id: fields.uf.fieldId,
          },
          field_domain_value: {
            id: field_domain_value_id,
          },
          user_iri: atob(localStorage.getItem(`user-endpoint`)),
        })
        .catch((err) => {
          console.error('Erro ao atualizar UF', err);
        });
    } else {
      api
        .put(`/general/field_values/${field_value_id}`, {
          field: {
            id: fields.uf.fieldId,
          },
          field_domain_value: {
            id: field_domain_value_id,
          },
        })
        .catch((err) => {
          console.error('Erro ao atualizar UF', err);
        });
    }
  };
  return (
    <Base>
      <Title value="Meus Dados" />
      <Container className="p-0 mb-5 pb-5">
        {load && (
          <Formik
            initialValues={user}
            enableReinitialize
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              setSubmitting(true);

              Object.keys(values).forEach((element) => {
                if (
                  element !== 'avatar' &&
                  element !== 'plainPassword' &&
                  element !== 'confirmPassword'
                ) {
                  Object.assign(initialFieldValues[element], {
                    value: values[element] || '',
                  });
                }
              });

              if (avatar && !user.avatar) {
                Object.assign(initialFieldValues['avatar'], {
                  fieldId: fields.avatar.fieldId,
                  value: 'blob',
                });
              } else {
                delete initialFieldValues.avatar;
              }

              if (initialFieldValues.uf && values.uf !== '') {
                //PUT
                updateUf(values.uf, initialFieldValues.uf.id);
              } else if (values.uf !== '') {
                //POST
                updateUf(values.uf);
              }
              // delete initialFieldValues.uf;
              const { uf, ...fieldValues } = initialFieldValues;
              api
                .post(`/general/fieldValuesBatch`, Object.values(fieldValues))
                .then(async (res) => {
                  localStorage.setItem(`user-name`, btoa(values.nome));
                  await api.put(
                    `/users/${atob(localStorage.getItem(`user-id`))}`,
                    {
                      name: values.nome,
                    },
                  );

                  if (
                    values.plainPassword &&
                    values.plainPassword.length >= 8
                  ) {
                    await changePasswordUser({
                      plainPassword: values.plainPassword,
                    });
                  }

                  setSubmitting(false);
                  setModal(true);
                })
                .catch((err) => {
                  console.error(err, initialFieldValues);
                  setSubmitting(false);
                });
              if (avatarUpdate && avatar !== null) {
                api.get('/users/me').then((res) => {
                  setSubmitting(true);
                  const data = new FormData();
                  data.append('file', avatar);
                  if (res.data.profile.avatar) {
                    api
                      .post(
                        `/general/field_values/${res.data.profile.avatar.id}/upload/file`,
                        data,
                        {
                          headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                          },
                        },
                      )
                      .then((response) => {
                        setSubmitting(false);
                      })
                      .catch((err) => {
                        console.error('Upload ERROR', err);
                        setSubmitting(false);
                      });
                  } else {
                    setSubmitting(false);
                  }
                });
              }
            }}>
            {({ errors, touched, values }) => (
              <Form>
                <FormStyle className="d-flex flex-column">
                  <Row className="mb-3 justify-content-center justify-content-lg-start">
                    <Col xs="12" md="3" lg="2">
                      <PerfilPhoto className="px-4">
                        <RoundedPhoto>
                          <img src={preview} alt="foto de perfil" />
                        </RoundedPhoto>
                        <input
                          id="photo"
                          type="file"
                          onChange={(e) => {
                            setAvatar(e.target.files[0]);
                            setAvatarUpdate(true);
                          }}
                          className="d-none"
                        />
                        <label className="text-center" htmlFor="photo">
                          <b>Alterar foto</b>
                        </label>
                      </PerfilPhoto>
                    </Col>
                    <Col xs="12" md="12" lg="4">
                      <Col xs="12">
                        <div className="has-float-label">
                          <Field
                            id="input_name"
                            name="nome"
                            className="w-100"
                            placeholder="Nome"
                          />
                          <label htmlFor="input_name">
                            <b>Nome</b>
                          </label>
                          {(errors.nome && touched.nome) ||
                            (showErros && errors.nome) ? (
                              <div className="Form-Error">{errors.nome}</div>
                            ) : null}
                        </div>
                      </Col>
                      <Col xs="12" className="pt-4">
                        <div className="has-float-label">
                          <Field
                            name="telefone"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                name="telefone"
                                mask={phoneNumberMask}
                                placeholder="Telefone"
                                type="text"
                              />
                            )}
                          />
                          <label htmlFor="input_tel">
                            <b>Telefone</b>
                          </label>
                          {(errors.telefone && touched.telefone) ||
                            (showErros && errors.telefone) ? (
                              <div className="Form-Error">{errors.telefone}</div>
                            ) : null}
                        </div>
                      </Col>
                    </Col>
                    <Col xs="12" md="12" lg="4" className="pt-4 pt-lg-0">
                      <Col xs="12">
                        <div className="has-float-label">
                          <Field
                            id="input_email"
                            disabled
                            type="text"
                            className="w-100"
                            name="email"
                            placeholder="Email"
                          />
                          <label htmlFor="input_email">
                            <b>Email</b>
                          </label>
                          {errors.email && touched.email ? (
                            <div className="Form-Error">{errors.email}</div>
                          ) : null}
                        </div>
                      </Col>
                    </Col>
                  </Row>
                  <Row className="mt-5 pt-5">
                    <Col xs="12" md="12" lg="6">
                      <Col xs="12">
                        <div className="has-float-label">
                          <Field
                            id="input_address"
                            type="text"
                            className="w-100"
                            name="logradouro"
                            placeholder="Endereço"
                          />
                          <label htmlFor="input_address">
                            <b>Endereço</b>
                          </label>
                          {(errors.logradouro && touched.logradouro) ||
                            (showErros && errors.logradouro) ? (
                              <div className="Form-Error">
                                {errors.logradouro}
                              </div>
                            ) : null}
                        </div>
                      </Col>
                      <Col xs="12" className="pt-4">
                        <div className="has-float-label">
                          <Field
                            id="input_city"
                            type="text"
                            className="w-100"
                            name="cidade"
                            placeholder="Cidade"
                          />
                          <label htmlFor="input_city">
                            <b>Cidade</b>
                          </label>
                          {(errors.cidade && touched.cidade) ||
                            (showErros && errors.cidade) ? (
                              <div className="Form-Error">{errors.cidade}</div>
                            ) : null}
                        </div>
                      </Col>
                    </Col>
                    <Col xs="12" md="12" lg="2" className="pt-4 pt-lg-0">
                      <Col xs="12">
                        <div className="has-float-label">
                          <Field
                            id="input_number"
                            type="text"
                            className="w-100"
                            name="numero"
                            placeholder="Número"
                          />
                          <label htmlFor="input_number">
                            <b>Número</b>
                          </label>
                          {(errors.numero && touched.numero) ||
                            (showErros && errors.numero) ? (
                              <div className="Form-Error">{errors.numero}</div>
                            ) : null}
                        </div>
                      </Col>
                      <Col xs="12" className="pt-4">
                        <div className="has-float-label">
                          <Field
                            name="cep"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={cepNumberMask}
                                placeholder="Digite seu CEP"
                                type="text"
                              />
                            )}
                          />
                          <label htmlFor="input_cep">
                            <b>CEP</b>
                          </label>
                          {(errors.cep && touched.cep) ||
                            (showErros && errors.cep) ? (
                              <div className="Form-Error">{errors.cep}</div>
                            ) : null}
                        </div>
                      </Col>
                    </Col>
                    <Col xs="12" md="12" lg="4" className="pt-4 pt-lg-0">
                      <Col xs="12">
                        <div className="has-float-label">
                          <Field
                            id="input_bairro"
                            type="text"
                            className="w-100"
                            name="bairro"
                            placeholder="Bairro"
                          />
                          <label htmlFor="input_bairro">
                            <b>Bairro</b>
                          </label>
                          {(errors.bairro && touched.bairro) ||
                            (showErros && errors.bairro) ? (
                              <div className="Form-Error">{errors.bairro}</div>
                            ) : null}
                        </div>
                      </Col>
                      <Col xs="12" className="pt-4">
                        <div className="has-float-label">
                          <label htmlFor="uf">
                            <b>UF</b>
                          </label>
                          <Field
                            name="uf"
                            as="select"
                            placeholder="Estado"
                            className="form-input">
                            <option value="">Selecione</option>
                            {ufs.map((state, index) => (
                              <option value={state.id} key={index}>
                                {state.value}
                              </option>
                            ))}
                          </Field>

                          {(errors.uf && touched.uf) ||
                            (showErros && errors.uf) ? (
                              <div className="Form-Error">{errors.uf}</div>
                            ) : null}
                        </div>
                      </Col>
                    </Col>
                  </Row>

                  <Row className="pt-5 mt-5">
                    <Col xs="12" md="12" lg="3">
                      <Col xs="12" className="pt-4">
                        <div className="has-float-label">
                          <Field
                            id="input_pass"
                            type="password"
                            className="w-100"
                            placeholder="Nova Senha"
                            name="plainPassword"
                            defaultValue={user.password}
                          />
                          <label htmlFor="input_pass">
                            <b>Redefinir Senha</b>
                          </label>
                          {(errors.plainPassword && touched.plainPassword) ||
                            (showErros && errors.plainPassword) ? (
                              <div className="Form-Error">
                                {errors.plainPassword}
                              </div>
                            ) : null}
                        </div>
                      </Col>
                      <Col xs="12" className="pt-4">
                        <div className="has-float-label">
                          <Field
                            id="input_confirm"
                            type="password"
                            className="w-100"
                            placeholder="Confirmar Nova Senha"
                            name="confirmPassword"
                            defaultValue={user.password}
                          />
                          <label htmlFor="input_confirm">
                            <b>Confirmar Senha</b>
                          </label>
                          {(errors.confirmPassword &&
                            touched.confirmPassword) ||
                            (showErros && errors.confirmPassword) ? (
                              <div className="Form-Error">
                                {errors.confirmPassword}
                              </div>
                            ) : null}
                        </div>
                      </Col>
                    </Col>
                    <Col
                      xs="12"
                      sm="12"
                      lg="9"
                      className="pt-4 pt-lg-0 d-flex justify-content-end align-items-center">
                      <button
                        type="submit"
                        disabled={submitting}
                        onClick={
                          Object.values(errors).length > 0
                            ? setShowErros(true)
                            : null
                        }
                        className="col-12 col-lg-3 LoadMoreButtom">
                        {submitting ? 'Salvando...' : 'Salvar'}
                      </button>
                    </Col>
                  </Row>
                </FormStyle>
              </Form>
            )}
          </Formik>
        )}
        <Modal isOpen={modal} toggle={toggle}>
          <ModalBody>Dados alterados com sucesso!!!</ModalBody>
          <ModalFooter>
            <Button className="buttomModal" onClick={toggle}>
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </Base>
  );
};
export default MeusDados;
