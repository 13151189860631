import React, {useState, useEffect} from 'react';
import Base from '../../../components/Theme/base';
import Title from '../../../components/Theme/Title';
import Filter from '../../../components/Theme/Filter';
import CourseItem from '../../../components/Home/Cursos/Distancia/CourseItem';
import {Container, Col, Row, Spinner} from 'reactstrap';
import api from '../../../services/api';
import userPlaceholder from './../../../assets/images/vaccinar_placeholder.png';
import {FilterStyle} from '.././../../components/Theme/Filter/styles';
import stripTags from 'striptags';

const CursoDistancia = () => {
  const [courses, setCourses] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterType, setFilterType] = useState('titulo');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const data = [];
    api
      .get('/lms/courses?order[highlight asc,courses.published_at ]=desc')
      .then((res) => {
        res.data.forEach((element) => {
          if (element.lessons.length > 0) {
            data.push({
              _id: `/lms/courses/${element.id}`,
              id: element.id,
              image: {
                urlPublica:
                  element.image !== null
                    ? element.image.urlPublica
                    : userPlaceholder,
              },
              nota: 5,
              duration: element.duration,
              data: element.created_at,
              views: element.views,
              photo:
                element.hasOwnProperty('profile') &&
                element.created_by.profile.hasOwnProperty('avatar') &&
                element.created_by.profile.avatar.file != null
                  ? element.reated_by.profile.avatar.file.urlPublica
                  : userPlaceholder,
              autor: element.hasOwnProperty('created_by')
                ? element.created_by.name
                : '',
              titulo: element.title,
              description: element.summary,
              url: `/online/lms/courses/${element.id}`,
            });
          }
        });
        setLoading(false);
        setCourses(data);
      })
      .catch((err) => {
        setLoading(false);
        console.error('Erro ao buscar Cursos: ', err);
      });

    return () => {
      setCourses([]);
    };
  }, []);

  const [qtd, setQtd] = useState(4);
  function expand(e) {
    setQtd((prevState) => (prevState = prevState + 4));
  }
  return (
    <Base>
      <Title value="Cursos Online" />
      {!loading ? (
        <>
          <Container className="p-0 my-5">
            <FilterStyle>
              <Row className="d-flex col-12 justify-content-end p-0 m-0">
                {/* <Col xs='12' md="2" className="p-0 px-md-2">
              <select onChange={(e) => setFilterType(e.target.value)} name="select" className="w-100">
                <option value="titulo">Título</option>
                <option value="description">Descrição</option>
              </select>
            </Col> */}

                <Col xs="12" md="3" className="p-0 d-flex search-filter">
                  <input
                    onKeyUp={(event) => setFilter(event.target.value)}
                    className="w-100"
                    placeholder="Digite aqui.."
                    type="text"
                  />
                  <i className="fas fa-search " />
                </Col>
              </Row>
            </FilterStyle>
          </Container>
          <Container className="p-0">
            <Row>
              {courses.slice(0, qtd).map((course, i) => {
                return filter === '' ||
                  stripTags(course[filterType])
                    .toLowerCase()
                    .toString()
                    .indexOf(filter.toLowerCase()) > -1 ||
                  stripTags(course['description'])
                    .toLowerCase()
                    .toString()
                    .indexOf(filter.toLowerCase()) > -1 ? (
                  <Col
                    key={`courseItem${i}`}
                    xs="12"
                    md="6"
                    lg="3"
                    className="mb-4 p-md-0">
                    {' '}
                    <CourseItem key={`courseItem${i}`} course={course} />{' '}
                  </Col>
                ) : (
                  ''
                );
              })}
            </Row>
            {courses.length === 0 && (
              <Row>
                <h1>Nenhum Curso disponível</h1>
              </Row>
            )}
            {courses.length > 0 && courses.length > qtd ? (
              <Row className="p-4 p-lg-0">
                <button
                  onClick={expand}
                  className="col-12 col-lg-3 LoadMoreButtom">
                  Carregar Mais
                </button>
              </Row>
            ) : (
              ''
            )}
          </Container>
        </>
      ) : (
        <Spinner />
      )}
    </Base>
  );
};
export default CursoDistancia;
