import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import {ContentPage} from './styles';
import {Container, Col, Row, Spinner} from 'reactstrap';
import api from '../../services/api';
import BlockSobre from './blocks/Sobre';
import hasProperty from '../../utils/hasProperty';

const SobrePage = () => {
  const [pageContent, setPageContent] = useState();
  const [blocks, setBlocks] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get('/cms/pages?slug=sobre')
      .then((pageData) => {
        const data = pageData.data[0].pages_blocks;
        const endpoints = [];
        data.forEach(({block}) => {
          endpoints.push(api.get(block.endpoint));
        });

        Promise.all(endpoints).then((res) => {
          const resEndpoints = [];
          res.forEach((item) => {
            resEndpoints.push(item.data);
          });
          setBlocks(resEndpoints);
        });

        setLoading(false);
        setPageContent(pageData.data[0]);
      })
      .catch((err) => {
        setLoading(false);
        console.error('Erro ao buscar Paginas...', err);
      });
  }, []);
  function comparar(a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    // a deve ser igual a b
    return 0;
  }

  return (
    <Base>
      <Title
        value={typeof pageContent !== 'undefined' ? pageContent.title : ''}
      />
      {pageContent && blocks && !loading ? (
        <Container className="p-0 mb-5 pb-5">
          <ContentPage>
            {pageContent.pages_blocks.map((block, i) => (
              <>
                {block.container === 'banner-top' && (
                  <BlockSobre key={block.id} banners={blocks[i].banners} />
                )}
              </>
            ))}
            {hasProperty(blocks[0], 'banners') && (
              <BlockSobre banners={blocks[0].banners} />
            )}

            {pageContent && blocks && (
              <Row className="content">
                {pageContent.pages_blocks.map((block) => (
                  <>
                    {block.container === 'left' && (
                      <Col key={block.id} lg="3">
                        <h2> {block.block.name}</h2>
                      </Col>
                    )}
                  </>
                ))}
                <Col
                  lg={`${
                    pageContent.pages_blocks.find(
                      (item) => item.container === 'left',
                    )
                      ? '8'
                      : '12'
                  }`}>
                  {pageContent && (
                    <div key={pageContent.id}>
                      <div
                        className="content-text"
                        dangerouslySetInnerHTML={{
                          __html: pageContent.content,
                        }}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </ContentPage>
        </Container>
      ) : (
        <Spinner />
      )}
    </Base>
  );
};
export default SobrePage;
